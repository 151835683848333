/**
 * Foursource page
 * https://foursource.com/sustainability/oekotex-x-foursource
 */

import * as React from "react";
import { withTrans } from "../../i18n/withTrans";
import Utm from "../../components/_global/utm";
import { pageTexts } from "../../content/oekotex-x-foursource";
import { searchTexts } from "../../content/pages/searchCerts";
import { StaticImage } from "gatsby-plugin-image";

// components
import Layout from "../../components/layout";
import TextImgBackgroundFull from "../../components/textImgBackgroundFull";
import Text from "../../components/text";
import TitleSeparator from "../../components/titleSeparator";
import Button from "../../components/_partials/_button";
import BulletsSection from "../../components/bulletsSection";

class oekoTexPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.searchTexts = searchTexts(this.t);
    this.searchTexts.searchText.subtitle.text = this.t("sustainability.oekotex-x-foursource.searchingOEKO");
  }

  render() {
    return (
      <>
        <Layout pageName="sustainability.oekotex-x-foursource" whiteMenu={false}>
          <TextImgBackgroundFull info={this.text.topInfo}></TextImgBackgroundFull>
          <div className="paddingSidesBig pt-30 pb-50">
            <Text text={this.text.firstText}></Text>
          </div>
          <div className="paddingSidesBig backgroundGrey topBottomDiagonal pt-30">
            <div className="pt-100 pb-100">
              <TitleSeparator info={this.text.whatIs}></TitleSeparator>
              <div className="pt-30">
                <TitleSeparator info={this.text.standards}></TitleSeparator>
                <div className="row row-cols-2 row-cols-lg-4 row-cols-md-2 pb-10">
                  {this.text.standardsCols.map((content, i) => (
                    <div className="col text-center" key={i}>
                      <div className="height-220px">
                        <Text text={content.text}></Text>
                      </div>
                      <div className="certImages">
                        {content.images.map((image, a) => (
                          <img key={a} className="w-100 p-lg-1 pl-md-5 pr-md-5 pl-sm-5 pr-sm-5 pr-5 pl-5 pb-20" src={image.src} alt={image.alt}></img>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="row justify-content-center pt-30">
                  <Button cta={this.text.certsButton}></Button>
                </div>
              </div>
            </div>
          </div>
          <div className="paddingSidesBig pt-30">
            <BulletsSection info={this.searchTexts.searchText}></BulletsSection>
            <a href={process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`)} target="_blank" rel="noreferrer">
              <div className="d-flex justify-content-center">
              <StaticImage
                placeholder="blurred"
                src="../../images/oeko-tex-04022020.png"
                alt={this.t("sustainability.oekotex-x-foursource.platformAlt")}
                className="h-100"
              />
              </div>
            </a>
            <div className="row justify-content-center pb-100">
              <Button cta={this.text.resultsButton}></Button>
            </div>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(oekoTexPage);
