import Utm from "../components/_global/utm";
// images
import TopBackground from "../images/oekotex.jpg";
import OekoStandard from "../images/oeko-tex-standard.png";
import OekoLeather from "../images/logo_leathersta.png";
import OekoGreen from "../images/oeko-tex-madeingreen.png";
import OekoStep from "../images/step-logo.png";
import OekoDetox from "../images/detox-to-zero.png";
import OekoEco from "../images/logo_eco_passport.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("sustainability.oekotex-x-foursource.topTitle"),
        colorClass: "colorLight",
      },
      subtitle: {
        text: t("sustainability.oekotex-x-foursource.topSubtitle"),
        colorClass: "colorLight",
      },
      bottomDiagonal: true,
      opacity: true,
      image: {
        src: TopBackground,
        alt: t("sustainability.oekotex-x-foursource.imgAlt"),
        smallHeight: true
      },
    },
    firstText: [
      {
        text: t("sustainability.oekotex-x-foursource.over25"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
    ],
    whatIs: {
      subtitle: {
        text: t("sustainability.oekotex-x-foursource.whatIs"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      text: {
        text: t("sustainability.oekotex-x-foursource.globalStandards"),
        colorClass: "colorDark",
        textType: "paragraph",
      },
      separator: true,
    },
    standards: {
      subtitle: {
        text: t("sustainability.oekotex-x-foursource.oekoCertificates"),
        colorClass: "colorDark",
        extraClass: "font40",
      },
      separator: true,
    },
    standardsCols: [
      {
        text: [
          {
            text: t("sustainability.oekotex-x-foursource.availableFor"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
        ],
        images: [
          { src: OekoStandard, alt: t("sustainability.oekotex-x-foursource.oekoStandardAlt") },
          { src: OekoLeather, alt: t("sustainability.oekotex-x-foursource.oekoLeatherAlt") },
        ],
      },
      {
        text: [
          {
            text: t("sustainability.oekotex-x-foursource.identifyTextiles"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
        ],
        images: [{ src: OekoGreen, alt: t("sustainability.oekotex-x-foursource.oekoGreenAlt") }],
      },
      {
        text: [
          {
            text: t("sustainability.oekotex-x-foursource.optimiseProcess"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
        ],
        images: [
          { src: OekoStep, alt: t("sustainability.oekotex-x-foursource.oekoStepAlt") },
          { src: OekoDetox, alt: t("sustainability.oekotex-x-foursource.oekoDetoxAlt") },
        ],
      },
      {
        text: [
          {
            text: t("sustainability.oekotex-x-foursource.identifyChemicals"),
            colorClass: "colorDark",
            textType: "paragraph",
          },
        ],
        images: [{ src: OekoEco, alt: t("sustainability.oekotex-x-foursource.oekoEcoAlt") }],
      },
    ],
    certsButton: {
      text: t("sustainability.oekotex-x-foursource.learnMore"),
      url: "https://www.oeko-tex.com/en/",
      type: "mainColor",
    },
    resultsButton: {
      text: t("sustainability.oekotex-x-foursource.seeMore"),
      url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
      type: "mainColor",
    },
    searchButton: {
      text: t("sustainability.oekotex-x-foursource.seeMore"),
      url: "https://www.oeko-tex.com/en/",
      type: "mainColor",
    },
  };
};
